import React from 'react';

const Page1 = () => (
  <div>
    <h1>Page Title</h1>
    <img src="https://picsum.photos/300" alt="Description of the image" />
    <p>This is a small description for Page.</p>    

    <script nowprocket nitro-exclude type="text/javascript" id="sa-dynamic-optimization" 
            data-uuid="558bc8a2-1a68-448d-92b9-a909938f1322" 
            src="data:text/javascript;base64,CiAgICB2YXIgc2NyaXB0ID0gZG9jdW1lbnQuY3JlYXRlRWxlbWVudCgic2NyaXB0Iik7CiAgICBzY3JpcHQuc2V0QXR0cmlidXRlKCJub3dwcm9ja2V0IiwgIiIpOwogICAgc2NyaXB0LnNldEF0dHJpYnV0ZSgibml0cm8tZXhjbHVkZSIsICIiKTsKICAgIHNjcmlwdC5zcmMgPSAiaHR0cHM6Ly9kYXNoYm9hcmQubGlua2dyYXBoLmNvbS9zY3JpcHRzL2R5bmFtaWNfb3B0aW1pemF0aW9uX2FscGhhLmpzIjsKICAgIHNjcmlwdC5kYXRhc2V0LnV1aWQgPSAiNTU4YmM4YTItMWE2OC00NDhkLTkyYjktYTkwOTkzOGYxMzIyIjsKICAgIHNjcmlwdC5pZCA9ICJzYS1keW5hbWljLW9wdGltaXphdGlvbi1sb2FkZXIiOwogICAgZG9jdW1lbnQuaGVhZC5hcHBlbmRDaGlsZChzY3JpcHQpOwogIA==">
    </script>
  </div>
);

export default Page1;